const storeInstances = {}
let id = 1

const instances = {
  set(element, key, instance) {
    if (typeof element.tdsEnhancers === 'undefined') {
      element.tdsEnhancers = {}
    }

    if (typeof element.tdsEnhancers[key] === 'undefined') {
      element.tdsEnhancers[key] = {id}
      id++
    }

    storeInstances[element.tdsEnhancers[key].id] = instance
  },

  get(element, key) {
    if (!element || typeof element.tdsEnhancers === 'undefined' || typeof element.tdsEnhancers[key] === 'undefined') {
      return null
    }

    return storeInstances[element.tdsEnhancers[key].id] || null
  },

  remove(element, key) {
    if (!element || typeof element.tdsEnhancers === 'undefined' || typeof element.tdsEnhancers[key] === 'undefined') {
      return
    }

    delete storeInstances[element.tdsEnhancers[key].id]
    delete element.tdsEnhancers[key]
    if (Object.keys(element.tdsEnhancers).length === 0) {
      delete element.tdsEnhancers
    }
  }
}

export { instances }
