import onDOMChanges from '../../shared/onDOMChanges'
import { instances } from '../../shared/instances'
import { CSS_NS, NAMESPACE } from '../../shared/constants'
import { dispatchEvent } from '../../shared/eventDispatcher'

const INSTANCE_KEY = `${NAMESPACE}Navigation`
const REMOVED_EVENT = 'navigation:removed'
const TOGGLE_EVENT = 'navigation:toggle'
const NAV_CLASSNAME = `${CSS_NS}nav`
const NAV_DISMISS_SELECTOR = '[data-dismiss="nav"]'

let nextId = 0

class _NavigationInstance {
  constructor (element, baseNavClass) {
    const privateData = this._private = {}
    privateData.element = element
    privateData.classes = navClasses(baseNavClass)
    this._init()
    element.dataset.enhancedNav = true
    instances.set(element, INSTANCE_KEY , this)
  }

  open () {
    this.toggle(true)
  }

  close () {
    this.toggle(false)
  }

  toggle (expand) {
    const toggle = this._topToggle
    const expanded = toggle && toggle.getAttribute('aria-expanded') === 'true'
    if (toggle && (arguments.length === 0 || expand !== expanded)) {
      toggleNavItem(toggle, this)
    }
  }

  get isOpen () {
    const topToggle = this._topToggle
    return !!topToggle && topToggle.offsetWidth && topToggle.offsetHeight && topToggle.getAttribute('aria-expanded') === 'true'
  }

  destroy () {
    const { element } = this._private
    this._removeEventHandlers(this)
    element.dataset.enhancedNav = false
    instances.remove(element, INSTANCE_KEY)
    delete this._private
  }

  _init () {
    const { element, classes } = this._private
    enhance(element, classes)
    this._addEventHandler(element, 'click', onExpandCollapse(this))
    this._addEventHandler(element, 'click', onCloseNav(this))
    this._addEventHandler(document, 'keydown', onEscape(this))
    this._addEventHandler(document, 'focusout', onFocusOut(this))
    this._addEventHandler(document, REMOVED_EVENT, this._checkDestroy.bind(this))
  }

  _addEventHandler (element, event, handler) {
    const handlers = this._private.handlers || (this._private.handlers = [])
    handlers.push({element, event, handler})
    element.addEventListener(event, handler)
  }

  _removeEventHandlers () {
    const handlers = this._private.handlers || []
    handlers.forEach(({element, event, handler}) => {
      element.removeEventListener(event, handler)
    })
  }

  _checkDestroy ({detail}) {
    if (detail === this._private.element) {
      this.destroy()
    }
  }

  get _topToggle () {
    return Array.from(this._private.element.children).find(node => node.matches('button[aria-expanded]'))
  }
}

function navClasses (baseClassName) {
  return {
    nav: baseClassName,
    navList: `${baseClassName}__list`,
    navDropdown: `${baseClassName}__dropdown`,
    navItem: `${baseClassName}__item`,
    navToggle: `${baseClassName}__toggle`,
    navToggleExpandedText: `${baseClassName}__toggle--expanded`,
    navToggleCollapsedText: `${baseClassName}__toggle--collapsed`
  }
}

function enhance (element, classes) {
  // find each nav-list
  const buttonToggleSelector = `button.${classes.navToggle}`
  const labelToggleSelector = `label.${classes.navToggle}`
  const toggleSelectors = [buttonToggleSelector, `[type="checkbox"] + ${labelToggleSelector}`]

  const selectors = []
  toggleSelectors.forEach(s1 => {
    selectors.push(`${s1} + *`)
  })

  const toggledNavGroups = Array.from(element.querySelectorAll(selectors.join()))
  toggledNavGroups.forEach(navGroup => {
    let checkbox, label, button
    const navGroupParent = navGroup.parentNode
    const siblings = navGroupParent.children
    for (let i = 0; i < siblings.length; i++) {
      const node = siblings[i]
      if (node.matches(buttonToggleSelector)) {
        button = node
      }
      else if (node.matches('[type="checkbox"]')) {
        checkbox = node
      }
      else if (node.matches(labelToggleSelector)) {
        label = node
      }
      else if (node === navGroup) {
        break
      }
    }

    if (checkbox) {
      navGroupParent.removeChild(checkbox)
    }

    if (label) {
      navGroupParent.removeChild(label)
    }

    if (!button && checkbox) {
      button = createToggleButton(label, classes)
      navGroup.parentNode.insertBefore(button, navGroup)
    }

    if (button) {
      button.hidden = false
      if (!button.getAttribute('aria-expanded')) {
        button.setAttribute('aria-expanded', !!(checkbox && checkbox.checked))
      }

      if (!button.getAttribute('aria-controls')) {
        if (!navGroup.id) {
          navGroup.id = `${classes.nav}-group_${++nextId}`
        }
        button.setAttribute('aria-controls', navGroup.id)
      }
    }
  })
  const closeButton = element.querySelector(NAV_DISMISS_SELECTOR)
  if (closeButton) {
    closeButton.hidden = false
  }
}

function onExpandCollapse (navigation) {
  return e => {
    // todo: should selector be more specific?
    const toggle = e.target.closest('button[aria-expanded]')
    if (toggle) {
      toggleNavItem(toggle, navigation)
    }
  }
}

function onCloseNav (navigation) {
  return e => {
    const closeButton = e.target.closest(NAV_DISMISS_SELECTOR)
    if (closeButton && navigation.isOpen) {
      navigation.close()
      const toggle = navigation._topToggle
      if (toggle) {
        toggle.focus()
      }
    }
  }
}

function onFocusOut (navigation) {
  return e => {
    const { element } = navigation._private
    if (navigation.isOpen) {
      const {target, relatedTarget} = e
      if (element.contains(target) && (!relatedTarget || !element.contains(relatedTarget))) {
        navigation.close()
      }
    }
  }
}

function onEscape (navigation) {
  return e => {
    switch (e.key || e.code) {
      case 'Esc':
      case 'Escape':
        if (navigation.isOpen) {
          navigation.close()
          const toggle = navigation._topToggle
          if (toggle) {
            toggle.focus()
          }
        }
    }
  }
}

function toggleNavItem (toggle, navigation, done) {
  const {element, classes} = navigation._private
  const expand = toggle.getAttribute('aria-expanded') !== 'true'
  const isTopToggle = toggle.parentNode === element
  const navGroupId = toggle.getAttribute('aria-controls')
  const navGroup = navGroupId && document.getElementById(navGroupId)
  const isTopLevelDropdown = isTopToggle && navGroup.matches(`.${classes.navDropdown}`)
  if (navGroup) {
    const toggleSubNav = () => {
      const subToggles = !isTopLevelDropdown && !expand && navGroup.querySelectorAll('button[aria-expanded="true"]')
      if (subToggles && subToggles.length) {
        toggleNavItem(subToggles[subToggles.length - 1], navigation, toggleSubNav)
      } else {
        if (!isTopLevelDropdown) {
          transitionNavItems(navGroup, classes, expand, done)
        }
        toggle.setAttribute('aria-expanded', expand)
        if (isTopToggle) {
          dispatchEvent(element, TOGGLE_EVENT, {detail: {expanded: expand}})
        }
      }
    }
    toggleSubNav()
  } else if (done) {
    done()
  }
}

function transitionNavItems (navGroup, classes, expand, done) {
  const heightDuration = 50
  const opacityDuration = heightDuration * 2
  const heightDelay = opacityDuration
  const opacityDelay = heightDuration * 2
  const navList = getNavList(navGroup, `.${classes.navList}`)
  const items = (navList ? Array.from(navList.children) : []).filter(isVisible)
  if (!expand) {
    items.reverse()
  }

  if (items.length) {
    const wrapup = () => {
      items.forEach(item => restoreStyles(item))
      restoreStyles(navGroup)
      if (done) {
        done()
      }
    }

    if (!expand) {
      // keep open until transition ends
      saveCurrentStyles(navGroup)
      navGroup.style.display = 'block'
    }

    const style = {
      overflow: 'hidden',
      transitionProperty: 'height, opacity',
      transitionDuration: `${heightDuration}ms, ${opacityDuration}ms`,
      transitionTimingFunction: 'linear, ease'
    }
    // prep each item
    items.forEach((item, index) => {
      const hDelay = heightDuration * index + (expand ? 0 : heightDelay)
      const oDelay = heightDuration * index + (expand ? opacityDelay : 0)
      style.height = expand ? 0 : item.scrollHeight + 'px'
      style.opacity = expand ? 0 : 1
      style.transitionDelay = `${hDelay}ms, ${oDelay}ms`
      if (index === items.length - 1) {
        // ease the last one in or out
        style.transitionDuration = `${heightDuration * 2}ms, ${opacityDuration}ms`
        style.transitionTimingFunction = 'ease-out, ease'
        onNavItemTransitionEnd(item, expand, wrapup)
      }
      saveCurrentStyles(item)
      applyStyles(item, style)
    })

    window.setTimeout(() => {
      // show or hide item
      items.forEach((item, index) => {
        item.style.height = expand ? item.scrollHeight + 'px' : 0
        item.style.opacity = expand ? 1 : 0
      })
    }, 10)
  } else if (done) {
    done()
  }
}

function getNavList (navGroup, navListSelector) {
  return navGroup.matches(navListSelector) ? navGroup : navGroup.querySelector(navListSelector)
}

// IE requires empty string rather than null to delete styles
// So, use this approach instead
const SAVE_STYLES_PROP = `${NAMESPACE}NavItemSavedStyles`
function saveCurrentStyles (el) {
  const style = el.getAttribute('style')
  if (style) {
    el[SAVE_STYLES_PROP] = style
  }
}

function restoreStyles (el) {
  if (el[SAVE_STYLES_PROP]) {
    el.setAttribute('style', el[SAVE_STYLES_PROP])
    delete el[SAVE_STYLES_PROP]
  }else {
    el.removeAttribute('style')
  }
}

function applyStyles (el, styles) {
  for (let style in styles) {
    if (styles.hasOwnProperty(style)) {
      el.style[style] = styles[style]
    }
  }
}

function isVisible(el) {
  const style = window.getComputedStyle(el)
  return style.display !== 'none' && style.visibility !== 'hidden'
}

function onNavItemTransitionEnd (item, expand, callback) {
  const onEnd = (e) => {
    if (e.target === item && e.propertyName === (expand ? 'opacity' : 'height')) {
      item.removeEventListener('transitionend', onEnd)
      callback()
    }
  }
  item.addEventListener('transitionend', onEnd)
}

function createToggleButton (label, classes) {
  const button = document.createElement('button')
  if (label) {
    button.innerHTML = label.innerHTML
    const nodes = Array. from (button.querySelectorAll(`.${classes.navToggleExpandedText}, .${classes.navToggleCollapsedText}`))
    nodes.forEach(node => node.parentNode.removeChild(node))

    button.className = label.className
    if (label.getAttribute('aria-label')) {
      button.setAttribute('aria-label', label.getAttribute('aria-label'))
    }
  } else {
    button.innerHTML = `<span class="tds-nav__toggle-icon"></span><span class="tds-sr-only">Submenu</span>`
    button.className = classes.navToggle
  }
  return button
}

// ///////////////////////////////////////////////////////////////////////////////////////////////////////

class Navigation {
  constructor (element, baseClassName=NAV_CLASSNAME) {
    this._instance = instances.get(element, INSTANCE_KEY) || new _NavigationInstance(element, baseClassName)
  }

  /**
   * Expands the top level navigation list.
   * Applicable only when navigation has a top level toggle the is in effect.
   */
  open () {
    this._instance.open()
  }

  /**
   * Collapses the top level navigation list.
   * Applicable only when navigation has a top level toggle the is in effect.
   */
  close () {
    this._instance.close()
  }

  /**
   * Expands ot collapses the top level navigation list.
   * Applicable only when navigation has a top level toggle the is in effect.
   *
   * @param {boolean} expand If passed, indicates whether to expand (true) or collapse (false) the navigation.
   * If not passed, then this method toggles the current state.
   */
  toggle (expand) {
    this._instance.toggle(expand)
  }

  /**
   * Detaches from the nav element, removes event listeners, and frees resources.
   */
  destroy () {
    this._instance.destroy()
    delete this._instance
  }

  /**
   * A static class method to register a custom base CSS class. Call this when using a custom class
   * generated using the Sass mixins
   *
   * @param {string} baseNavClass The base classname used to generate the custom class
   */
  static registerBaseClassName (baseNavClass) {
    onDOMChanges(`.${baseNavClass}`,
      function onNavAdded (element) {
        if (!element.dataset.enhancedNav) {
          new _NavigationInstance(element, baseNavClass)
        }
      },
      function onNavRemoved (element) {
        dispatchEvent(document, REMOVED_EVENT, {detail: element})
      })

    onDOMChanges(`.${baseNavClass}__item-text `, element => {
      if (!element.dataset.fixedWidth && element.innerText) {
        element.dataset.fixedWidth = element.innerText
      }
    })
  }
}

Navigation.registerBaseClassName(NAV_CLASSNAME)

export { Navigation }
