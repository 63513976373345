/**
 * Cross-browser (meaning IE) function to create an Event to dispatch. Creates a Custom Event
 * @param eventType {string} - The name of the event
 * @param eventInit {object} - Dictionary with the event initialization values as defined in https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent.
 *        For this function, though, bubbles defaults to true
 * @return {CustomEvent} A CustomEvent object
 */
const createEvent = (eventType, eventInit = {}) => {
  eventInit = {...eventInit}
  const additionalProperties = stripNonCustomEventInitProperties(eventInit)
  let event
  const init = {...{bubbles: true}, ...eventInit}
  if ('CustomEvent' in window && typeof window.CustomEvent === 'function') {
    event = new window.CustomEvent(eventType, init)
  } else {
    event = document.createEvent('CustomEvent')
    const bubbles = !!init.bubbles
    const cancelable = !!init.cancelable
    const details = init.detail
    event.initCustomEvent(eventType, bubbles, cancelable, details)
  }

  // add any additioab properties as read-only
  Object.keys(additionalProperties)
    .forEach(key => {
      Object.defineProperty(event, key, {
        get() {
          return additionalProperties[key]
        }
      })
    })

  return event
}

/**
 * Dispatches a (Custom) Event for the target
 * @param target {EventTarget} - The target to dispatch the event on
 * @param eventType {string} - The name of the event
 * @param eventInit {object} - Dictionary with the event initialization values as defined in https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent.
 *        For this function, though, bubbles defaults to true
 * @return {boolean} False if event is cancelable and at least one of the event handlers called Event.preventDefault(); otherwise true.
 */
const dispatchEvent = (target, eventType, eventInit) => {
  return target.dispatchEvent(createEvent(eventType, eventInit))
}

function stripNonCustomEventInitProperties (eventInit) {
  const properties = 'detail bubbles cancelable composed'
  const strippedProperties = {}
  Object.keys(eventInit).forEach(key => {
    if (properties.indexOf(key) === -1) {
      strippedProperties[key] = eventInit[key]
      delete eventInit[key]
    }
  })
  return strippedProperties
}
export {
  dispatchEvent,
  createEvent
}
