/**
 * Calls a function when the DOM is loaded
 * @param callback {function} The function to call when the DOM is loaded
 */
const whenDOMReady = callback => {
  if (document.readyState === "loading") {
    document.addEventListener('DOMContentLoaded', callback)
  }
  else {
    callback()
  }
}

export default whenDOMReady
