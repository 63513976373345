import whenReady from './when-dom-ready'

let observer
let nextId = 1
const listeners = []
function onDomChanges (selector, onNodeAdded, onNodeRemoved)  {
  const id = nextId++
  listeners.push({id, selector, onNodeAdded, onNodeRemoved})

  whenReady(() => {
    if ('function' === typeof onNodeAdded) {
      const existingNodes = document.body.querySelectorAll(selector)
      for(let i=0; i < existingNodes.length; i++) {
        onNodeAdded(existingNodes[i])
      }
    }

    if (!observer) {
      observer = new MutationObserver(observerCallback)
      observer.observe(document.body, {childList: true, subtree: true})
    }
  })

  const cancel = () => {
    for(let i =0; i< listeners.length; i++) {
      if (listeners[i].id === id) {
        listeners.splice(i,1)
        break;
      }
    }
  }
  return cancel
}

function observerCallback( mutationList) {
  mutationList.forEach((mutation) => {
    if (mutation.type === 'childList') {
      onChildListUpdates(mutation)
    }
  })
}

function onChildListUpdates({addedNodes, removedNodes}) {
  listeners.forEach(({selector, onNodeAdded, onNodeRemoved}) => {
    if (('function' === typeof onNodeAdded) && addedNodes && addedNodes.length) {
      dispatchUpdates(selector, addedNodes, onNodeAdded)
    }
    if (('function' === typeof onNodeRemoved) && removedNodes && removedNodes.length) {
      dispatchUpdates(selector, removedNodes, onNodeRemoved)
    }
  })
}

function dispatchUpdates(selector, nodeList, callback) {
  const matchedNodes = []
  for (let i = 0; i < nodeList.length; i++ ) {
    const node = nodeList[i]
    if (node instanceof HTMLElement) {
      if (node.matches(selector)) {
        matchedNodes.push(node)
      }
      const childNodes = node.querySelectorAll(selector)
      for (let j = 0; j< childNodes.length; j++) {
        matchedNodes.push(childNodes[j])
      }
    }
  }
  matchedNodes.forEach(callback)
}

export default onDomChanges
